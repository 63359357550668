


.tc-ic-diags .titledots > button {
  border: none;
  font-weight: 500;
  font-size: 1.1em;
  padding: 2px 16px;
  background-color: #f1f1f1;
  color: #53575a;
  border: 1px solid #ccc;
}

@media(width > 900px) {
  .tc-ic-diags .titledots > button {
    font-size: 1.25em;
  }
}

.tc-ic-diags .titledots > button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tc-ic-diags .titledots > button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tc-ic-diags .titledots > button:hover {
  background-color: rgb(209, 230, 249);
}

.tc-ic-diags .titledots > button.carousel__dot--selected {
  background-color: #237cc1;
  color: white;
  border-color: #237cc1;
}

