
.tc-location-search {
  text-align: left;
  font-family: sans-serif;
  font-size: 14px;
  position: relative;
  max-width: 800px;
}

.tc-location-search > .search-input,
.tc-location-search > .suggestions {
  width: 100%;
}

.tc-location-search > .search-input {
  position: relative;
  color: black;
  border: 2px solid rgb(112, 112, 112);
  border-radius: 6px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUtJREFUeNrs1U0oBGEcx3Err0VicSc1OSqJcraKiwMXB0lSbjjszYWUm6S0e8FellyUKKWUHKQ4uGhSXnIgR2FLMb5P/Q9P2+gZ69ktNf/6NLPP0+xvn31eJuJ5XlEhKhIG/e8gx3H0j/VoxSsu8en3jOu6vt9VHODHVGMNTzjGBe4x+JsRlRj6S3GALuxhH1GMY0ueT9sIGpGQOcxq7QmcYhk7yJiCTH/dAF4wn9X+jEWZt+4gIzIFNeIBHz59N3JtsBF0hxbU+vS1y/XWRlAK5VhFmdbehjiucWZjMexiQxZFJw5Rhz4JHv5pP+Wyj0YxhS+MoR9HeMc2hmwFqaNjCc2oQgV6cYVKJNFkI0ivNxmZqgnZPzXYzJrDPwfpdY5Jue/AQr6CVK1jRe6n0ZOvIFUzOJFTPeflHaTUqRGTuXoM37BhkLG+BRgAwGBahbN+tUYAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: right center;
  z-index: 2;
  outline: none;
  transition: border-color 0.25s;
  padding: 6px;
}

.tc-location-search > .search-input:not(:focus) {
  border-color: #ccc;
}

.tc-location-search > .suggestions {
  z-index: 1;
  position: absolute;
  background: white;
  top: calc(100% - 22px);
  border: 2px solid rgb(120, 120, 120);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
  color: #666;
  padding: 2px;
  padding-top: 22px;
}

.tc-location-search > .suggestions > .suggestion {
  padding: 2px 6px;
  cursor: pointer;
}

.tc-location-search > .suggestions > .suggestion:hover,
.tc-location-search> .suggestions > .suggestion.selected {
  background-color: #7ec7ff;
  border-radius: 3px;
  color: black;
}
