  
body{
  text-align: center;
  overscroll-behavior-x: none;
}

.homepage .header {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 20px 10px;
  margin-bottom: 20px;
}

.homepage .header .logo {
  width: 175px;
  margin: auto;
}
.homepage .leaflet-control{
  float: none;
  position: relative;
}
.homepage .leaflet-top {
  position: relative;
}

.homepage .tc-location-search {
  margin: 0 auto;
  max-width: 95vw;
  width: 400px;
}

#splash {
  position: relative;
  text-align: center;
  max-width: 800px;
  margin: 4px auto 10vh auto;
}

#splash img.diag {
  width: 80%;
  max-width: 90vw;
}

#splash {
  padding: 8px;
  font-size: 14px;
  margin-top: 32px;
}

#splash h2 {
  color: #799b3e;
  font-size: 1.6em;
  margin-bottom: 14px;
}

@media(width>900px) {
  #splash {
    font-size: 16px;
  }
}

h1 {
  font-size: 19pt;
  font-weight: 500;
  margin-bottom: 32px;
  margin-top: 8px;
}

h3 {
  margin: 10px auto;
  font-size: 17px;
}

p {
  line-height: 1.5;
}

@media(width<900px) {
  h1 {
    font-size: 16pt;
  }
}