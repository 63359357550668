.leaflet-container {
  width: 100vw;
  height: calc(100% - 80px);
  position: absolute;
}

#lengths{
  position: absolute;
  top: 30;
  z-index: 9998;
  text-align: center;
  width: 200px;
  left: 50%;
  margin-left: -100px; /* half of the width */
  background-color:white;
  border: 1px black solid;
  color: red;
}

#request{
  z-index: 9998;
  text-align: center;
  margin-top: 5px;
  float: right;
}

.header-map {
  background-color: #f1f1f1;
  padding: 20px 10px;
  position: relative;
  z-index: 1001;
  height: 80px;
}

.header-map .logo {
  font-size: 25px;
  font-weight: bold;
  width: 175px;
  /* margin: auto; */
  float: left;
}

.legend {
  padding: 6px 8px;
  font: 14px Arial, Helvetica, sans-serif;
  background-color: rgba(241, 241, 241, 0.8);
  text-align: left;
  line-height: 24px;
  color: #555;
  z-index: 9999;
}

.legend h4 {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
  color: #777;
}

.legend .legend-label {
  height: 25px;
}

.legend .legend-label i.circle {
  position: relative;
  top: 2px;

}

.legend span {
  position: relative;
  bottom: 3px;
}

.legend i {
  width: 19px;
  height: 19px;
  float: left;
  margin: 0 8px 0 0;
  opacity: 0.7;
  border: 1px solid rgba(255, 255, 255, 0.5);
}


.legend .legend-label a {
  display: block;
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 8px;
  padding-right: 8px;
  color: #237cc1;
  text-decoration: none;
}

.legend .legend-label a:hover {
  background-color: #237cc1;
  background: linear-gradient(#237cc1aa, #237cc1ee);
  color: white;
}

.leaflet-control-zoom{
  display:none;
}

.distance-result {
  position: absolute;
  top: calc(100% + 8px);
  width: 350px;
  margin-left: -175px;
  left: 50%;
  background-color: #f1f1f1;
  z-index: 1002;
  padding: 8px;
  font-size: 16px;
  border-radius: 3px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  transition: opacity 1s;
  opacity: 0;

}

.distance-result.shown {
  opacity: 1;
}

.distance {
  font-weight: bold;
  color: red;
}

.trucking-prompt {
  font-weight:500;
  font-size: 13px;
}

@media (max-width: 500px) {
  .leaflet-top .leaflet-control.legend {
    margin-top: 78px;
  }
}
